/* html, body, div#root {
  height: 100vh;
} */

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: normal;

  .page-title {
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
}

